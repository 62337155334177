import packageInfo from '../package.json'

const styles = [
  'color: white',
  'background: rgb(45,212,191)',
  'background: linear-gradient(90deg, rgba(237,218,206,1) 0%, rgba(200,82,8,1) 100%)',
  'font-size: 20px',
  'border-radius: 10px',
  'padding: 5px 10px',
].join(';')

const versionInfo = () => {
  return console.log('%c%s', styles, `${packageInfo.name} VERSION ${packageInfo.version}`)
}

export default versionInfo