import React, { lazy, Suspense } from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

const Main = lazy(() => import(`./vendor/${process.env.REACT_APP_CLIENT}/${process.env.REACT_APP_PROJECT}/Main`))

function App() {
  return (
    <Suspense fallback={<></>}>
      <Router>
        <Switch>
          <Route path="/p/:id">
            <Main />
          </Route>
          <Route path="*">
            <Main />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App
