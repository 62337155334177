if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-undef
  if (window._env_) __webpack_public_path__ = `${window._env_.RESOURCE_URL || ''}/${window._env_.SYSTEM.promotion || ''}/`
  // eslint-disable-next-line no-undef
  else __webpack_public_path__ = '/'
  // eslint-disable-next-line no-undef
  window.__webpack_public_path__ = __webpack_public_path__
}

export { }
